// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-apply-aus-tsx": () => import("./../../../src/pages/apply-aus.tsx" /* webpackChunkName: "component---src-pages-apply-aus-tsx" */),
  "component---src-pages-apply-tsx": () => import("./../../../src/pages/apply.tsx" /* webpackChunkName: "component---src-pages-apply-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-instant-loans-tsx": () => import("./../../../src/pages/instant-loans.tsx" /* webpackChunkName: "component---src-pages-instant-loans-tsx" */),
  "component---src-pages-no-credit-tsx": () => import("./../../../src/pages/no-credit.tsx" /* webpackChunkName: "component---src-pages-no-credit-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */)
}

